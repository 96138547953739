import { h, render } from "preact";
import type {
  MbbBundleEvent,
  ShoppingCartTransferModel,
  VoiceAddToCartEvent,
  voiceShopStoreStateType,
} from "../shop-types";
import { useEffect, useState } from "preact/hooks";
import {
  Mbb_SHOPPING_CART_EVENTS,
  SHOPPING_CART_EVENTS,
} from "Scripts/Src/Components/ShoppingCart/Components/shopping-cart-constants";
import { fetchProductBlockDataService } from "../Helpers/subscription-builder.service";
import { MapProductDataToSubScription } from "../shop-cart.store";
import type { ProductBlockDataModel } from "../Helpers/subscription-builder.types";
import { DialogLoader } from "./Dialogs/dialog-loader";

export type ShoppingCartEventHandlerProps = {
  useVoiceStore: voiceShopStoreStateType;
  setOpenButtonRef: (caller: HTMLElement) => void;
  toggleShoppingCart: (open: boolean, offeringId?: string) => void;
};

export enum DialogState {
  none = 0,
  default = 1,
  external = 2,
  maxOfferings = 3,
}

const isAnyServiceIsDefaultSelected = (productData: ProductBlockDataModel, preselectServiceId: string): boolean => {
  if (preselectServiceId) return true;

  return productData.subscriptionServices.some((s) => s.preselected === true);
};

export const shoppingCartEventHandler = ({
  useVoiceStore,
  setOpenButtonRef,
  toggleShoppingCart,
}: ShoppingCartEventHandlerProps) => {
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.none);
  const [productData, setProductData] = useState<ProductBlockDataModel | undefined>();

  const getNumberOfProducts = useVoiceStore((s) => s.numberOfProducts);
  const addMbbOffering = useVoiceStore((s) => s.addMbbOffering);
  const addVoiceOffering = useVoiceStore((s) => s.addVoiceOffering);
  const maxOrders = useVoiceStore((s) => s.getMaxOrders);

  const closeAndOpenCart = (openCard: boolean, offeringsId?: string) => {
    setDialogState(DialogState.none);
    toggleShoppingCart(openCard, offeringsId);
  };

  const voiceOpenServiceSelector = async (event: CustomEvent<ShoppingCartTransferModel>): Promise<void> => {
    const transferModel = event.detail;

    setOpenButtonRef(transferModel.openButton);

    try {
      const productData = await fetchProductBlockDataService(transferModel.blockId);
      productData.productPage = transferModel.productPage;
      setProductData(productData);

      if (!transferModel.adding) {
        return;
      }

      if (getNumberOfProducts() >= maxOrders()) {
        setDialogState(DialogState.maxOfferings);
        return;
      }

      const isPresetServiceSelected = isAnyServiceIsDefaultSelected(productData, transferModel.preselectServiceId);

      if (productData.showExternalServiceDialog && !isPresetServiceSelected) {
        setDialogState(DialogState.external);
      } else {
        setDialogState(DialogState.none);

        const sub = MapProductDataToSubScription(productData, transferModel.preselectServiceId);
        const offeringId = addVoiceOffering(sub);
        toggleShoppingCart(true, offeringId);
      }
    } catch (error) {
      console.error("Failed to fetch product data", error);
      return;
    }
  };

  const mbbAddProductEventHandler = (e: CustomEvent<MbbBundleEvent>) => {
    setOpenButtonRef(e.detail.openButton);

    if (getNumberOfProducts() >= maxOrders()) {
      setDialogState(DialogState.maxOfferings);
      return;
    }

    addMbbOffering(e.detail.mbbBundle);

    toggleShoppingCart(true);
  };

  const addVoiceOfferingEvent = (e: CustomEvent<VoiceAddToCartEvent>) => {
    if (getNumberOfProducts() >= maxOrders()) {
      setDialogState(DialogState.maxOfferings);
      return;
    }

    const { sub } = e.detail;
    addVoiceOffering(sub);

    toggleShoppingCart(true);
  };

  useEffect(() => {
    const voiceOpenServiceSelectorEventHandlerAsyncWrapper = (e: CustomEvent<ShoppingCartTransferModel>) => {
      void voiceOpenServiceSelector(e);
    };

    document.addEventListener(
      SHOPPING_CART_EVENTS.OPEN_SERVICE_SELECTOR_VIEW,
      voiceOpenServiceSelectorEventHandlerAsyncWrapper as EventListener,
    );
    document.addEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT, addVoiceOfferingEvent as EventListener);
    document.addEventListener(Mbb_SHOPPING_CART_EVENTS.ADD_PRODUCT, mbbAddProductEventHandler as EventListener);

    return () => {
      document.removeEventListener(
        SHOPPING_CART_EVENTS.OPEN_SERVICE_SELECTOR_VIEW,
        voiceOpenServiceSelectorEventHandlerAsyncWrapper as EventListener,
      );
      document.removeEventListener(SHOPPING_CART_EVENTS.ADD_PRODUCT, addVoiceOfferingEvent as EventListener);
      document.removeEventListener(Mbb_SHOPPING_CART_EVENTS.ADD_PRODUCT, mbbAddProductEventHandler as EventListener);
    };
  }, []);

  useEffect(() => {
    const modalContainer = document.querySelector<HTMLElement>("#ice-modal-container");
    if (!modalContainer) {
      throw new Error("Could not open DialogLoader: '#ice-modal-container element' not found");
    }

    render(
      h(DialogLoader, {
        dialogState,
        productData,
        closeAndOpenCart,
        useVoiceStore: useVoiceStore,
      }),
      modalContainer,
    );
  }, [dialogState]);
};
