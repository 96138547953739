import { KeyName } from "@shared/Utils/key-names";
import { RefObject } from "preact";
import { useEffect } from "preact/hooks";

type KeyDownEventCallback = (event: KeyboardEvent) => void;

export function useKeyDown(
  targetKey: KeyName,
  callback: KeyDownEventCallback,
  targetRef?: RefObject<Document | HTMLElement>,
): void {
  useEffect(() => {
    const element = targetRef?.current ?? window;

    if (!element) {
      return;
    }

    function handleKeyDown(e: Event) {
      const event = e as KeyboardEvent;
      if (event.key === targetKey) {
        callback(event);
      }
    }

    element.addEventListener("keydown", handleKeyDown);
    return () => {
      element.removeEventListener("keydown", handleKeyDown);
    };
  }, [targetKey, callback, targetRef]);
}
