import { h, type FunctionalComponent } from "preact";
import { ShoppingCartIcon } from "@shared/Components";
import { Dispatch, type StateUpdater } from "preact/hooks";
import { HeaderToolTip } from "@shared/Components/HeaderToolTip/headerToolTip";

type ShoppingCartBurgerProps = {
  productCount: number;
  totalSum: number;
  setOpenShoppingCart: Dispatch<StateUpdater<boolean>>;
  openShoppingCart: boolean;
  setShoppingBurgerRef: (value: HTMLElement) => void;
};

export const ShoppingCartBurger: FunctionalComponent<ShoppingCartBurgerProps> = ({
  productCount,
  setOpenShoppingCart,
  openShoppingCart,
  totalSum,
  setShoppingBurgerRef,
}: ShoppingCartBurgerProps) => {
  const onClickHandler = () => {
    setOpenShoppingCart(!openShoppingCart);
  };

  const onKeyDownHandler = (e: KeyboardEvent): void => {
    if (e.key === "Enter") {
      setOpenShoppingCart(!openShoppingCart);
    }
  };

  return (
    <HeaderToolTip text="Handlekurv" buttonId="handlekurv-trigger" setbuttonRef={setShoppingBurgerRef}>
      <button
        id="handlekurv-trigger"
        type="button"
        aria-haspopup="true"
        aria-expanded={openShoppingCart}
        className="shopping-cart__burger"
        onClick={onClickHandler}
        onKeyDown={onKeyDownHandler}
        aria-controls="shopping-burger-aria-live-id"
      >
        <ShoppingCartIcon />
        {productCount > 0 && (
          <div className="shopping-cart__burger__num" aria-hidden="true">
            {productCount}
          </div>
        )}
        <div className="visually-hidden" role="status" id="shopping-burger-aria-live-id">
          {productCount > 0 && <span>Det er {productCount} produkter i handlekurven.</span>}
          {productCount === 0 && <span>Det er ingen produkter i handlekurven.</span>}
        </div>
        <div className="visually-hidden" role="status" id="shopping-burger-price">
          {totalSum > 0 && <span>Kostnaden er {totalSum} kroner i måneden for produktene i handlekurven.</span>}
        </div>
        <div className="shopping-cart__burger__total_price" aria-labelledby="shopping-burger-price" aria-hidden="true">
          {totalSum > 0 ? `${totalSum.toString().replace(".", ",")},- /mnd` : null}
        </div>
      </button>
    </HeaderToolTip>
  );
};
