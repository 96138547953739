import { Fragment, type FunctionalComponent, h } from "preact";
import SubscriptionView from "./subscription-view";
import type { MbbBundle, Offering, Subscription, voiceShopStoreStateType } from "../shop-types";
import { useEffect, useState } from "preact/hooks";
import { MbbSubscriptionView } from "./mbb-subscription-view";

type SubscriptionListViewProps = {
  removeOffering: (id: string) => void;
  removeService: (id: string, serviceName: string) => void;
  offerings: Offering[];
  useVoiceStore: voiceShopStoreStateType;
};

export const OfferingsListView: FunctionalComponent<SubscriptionListViewProps> = ({
  removeOffering,
  removeService,
  offerings,
  useVoiceStore,
}: SubscriptionListViewProps) => {
  const isMbb = useVoiceStore((state) => state.isMbb);

  return (
    <div className="subscriptions">
      <ul className="subscriptions__list">
        {isMbb() ? (
          <MbbFilteredSubscriptionView
            offerings={offerings}
            removeOffering={removeOffering}
            useVoiceStore={useVoiceStore}
          />
        ) : (
          offerings.map((sub) => (
            <SubscriptionView
              key={sub.offeringId}
              sub={sub as Subscription}
              removeOffering={removeOffering}
              removeService={removeService}
              useVoiceStore={useVoiceStore}
            />
          ))
        )}
      </ul>
    </div>
  );
};

type MbbOfferingsListViewProps = {
  removeOffering: (id: string) => void;
  offerings: Offering[];
  useVoiceStore: voiceShopStoreStateType;
};

export const MbbFilteredSubscriptionView: FunctionalComponent<MbbOfferingsListViewProps> = ({
  removeOffering,
  offerings,
  useVoiceStore,
}: MbbOfferingsListViewProps) => {
  const [filteredOfferings, setFilteredOfferings] = useState<Offering[] | null>(null);
  const getOfferings = useVoiceStore((state) => state.getOfferings);

  useEffect(() => {
    setFilteredOfferings(getOfferings());
  }, [offerings]);

  return (
    <Fragment>
      {filteredOfferings?.map((sub) => (
        <MbbSubscriptionView key={sub.offeringId} sub={sub as MbbBundle} removeOffering={removeOffering} />
      ))}
    </Fragment>
  );
};
