import { h, FunctionalComponent } from "preact";
import { CloseIcon } from "@shared/Components";

interface ShoppingCartHeaderProps {
  closeShoppingCart: (open: boolean) => void;
  productCount: number;
}

export const ShoppingCartHeader: FunctionalComponent<ShoppingCartHeaderProps> = ({
  closeShoppingCart,
  productCount,
}: ShoppingCartHeaderProps) => {
  return (
    <header className="shopping-cart__header">
      <div id="shopping-cart-heading" className="shopping-cart__title" aria-hidden="true">
        Din handlekurv {productCount > 0 ? `(${productCount})` : ""}
      </div>
      <span
        aria-label={
          productCount > 0 ? `Din handlekurv med ${productCount} produkter` : "Din handlekurv, ingen produkter valgt"
        }
      ></span>
      <button aria-label="Lukk handlekurv" className="shopping-cart__close" onClick={() => closeShoppingCart(false)}>
        <CloseIcon />
      </button>
    </header>
  );
};
