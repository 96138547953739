import { Price } from "@shared/Components";
import { VisuallyHidden } from "@shared/Components/VisuallyHidden/visually-hidden";
import { FunctionalComponent, h, JSX } from "preact";
import { useState, useEffect } from "preact/hooks";
import { getOrderFormWebCheckoutUrl, getSubIds, getCheckoutUrlMbb } from "../Helpers/shopping-cart-utils";
import { Offering, voiceShopStoreStateType, MbbPrices, MbbBundle } from "../shop-types";
import { SHOPPING_CART_EVENTS, Mbb_SHOPPING_CART_EVENTS } from "./shopping-cart-constants";

type VoiceShoppingCartFooterProps = {
  offerings: Offering[];
  totalSum: number;
  toggleShoppingCart: (open: boolean) => void;
};

export const VoiceShoppingCartFooter: FunctionalComponent<VoiceShoppingCartFooterProps> = ({
  offerings,
  totalSum,
  toggleShoppingCart,
}: VoiceShoppingCartFooterProps) => {
  return (
    <footer className="price-summary">
      <div className="price-summary__price-line">
        <span className="text--bold-700">Total pris</span>
        <span aria-hidden="true">
          <Price amount={totalSum} useKr={true} addMnd={true} />
        </span>
        <VisuallyHidden>{totalSum} kroner.</VisuallyHidden>
      </div>
      <div className="price-summary__actions">
        <ContinueShoppingBtn toggleShoppingCart={toggleShoppingCart} />
        <CheckoutBtn
          href={getOrderFormWebCheckoutUrl(getSubIds(offerings))}
          onClickCallback={() => {
            document.dispatchEvent(new CustomEvent(SHOPPING_CART_EVENTS.GO_TO_CHECKOUT));
          }}
        />
      </div>
    </footer>
  );
};

interface MbbShoppingCartFooterProps {
  offerings: Offering[];
  useVoiceStore: voiceShopStoreStateType;
  toggleShoppingCart: (open: boolean) => void;
}

export const MbbShoppingCartFooter: FunctionalComponent<MbbShoppingCartFooterProps> = ({
  offerings,
  useVoiceStore,
  toggleShoppingCart,
}: MbbShoppingCartFooterProps) => {
  const getPrices = useVoiceStore((state) => state.getPrice);
  const [mbbSum, setMbbSum] = useState<MbbPrices>(getPrices() as MbbPrices);

  useEffect(() => {
    setMbbSum(getPrices() as MbbPrices);
  }, [offerings]);

  return (
    <footer className="price-summary">
      <div className="price-summary__price-line">
        <span className="text--bold-700">Total pris per måned:</span>
        <span className="" aria-hidden="true">
          <Price amount={mbbSum.total} useKr={true} addMnd={true} />
        </span>
        <VisuallyHidden>{mbbSum.total} kroner.</VisuallyHidden>
      </div>
      {mbbSum.totalAfterCampaign > 0 && mbbSum.totalAfterCampaign !== mbbSum.total && (
        <div className="price-summary__price-line">
          <span className="">*Pris etter kampanjeperiode:</span>
          <span className="" aria-hidden="true">
            <Price amount={mbbSum.totalAfterCampaign} useKr={true} addMnd={true} />
          </span>
          <VisuallyHidden>{mbbSum.total} kroner per måned.</VisuallyHidden>
        </div>
      )}
      <div className="price-summary__price-line">
        <span>Frakt:</span>
        <span aria-hidden="true">
          <Price amount={mbbSum.shippingPrice} useKr={true} />
        </span>
        <VisuallyHidden>{mbbSum.shippingPrice} kroner.</VisuallyHidden>
      </div>
      <div className="price-summary__price-line">
        <span>Totalt å betale nå:</span>
        <span aria-hidden="true">
          <Price amount={mbbSum.totalUpfront} useKr={true} />
        </span>
        <VisuallyHidden>{mbbSum.totalUpfront} kroner.</VisuallyHidden>
      </div>
      <div className="price-summary__actions">
        <ContinueShoppingBtn toggleShoppingCart={toggleShoppingCart} />
        <CheckoutBtn
          href={getCheckoutUrlMbb(offerings as MbbBundle[])}
          onClickCallback={() => document.dispatchEvent(new CustomEvent(Mbb_SHOPPING_CART_EVENTS.GO_TO_CHECKOUT))}
        />
      </div>
    </footer>
  );
};

type CheckoutBtnProps = JSX.HTMLAttributes<HTMLAnchorElement> & {
  onClickCallback: () => void;
};

const CheckoutBtn: FunctionalComponent<CheckoutBtnProps> = ({ onClickCallback, ...delegated }: CheckoutBtnProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    setIsDisabled(true);
    onClickCallback();
  };
  return (
    <a id="btn-goto-order" className={"btn btn--primary"} {...delegated} onClick={handleClick}>
      Til bestilling
    </a>
  );
};

type ContinueShoppingBtnProps = {
  toggleShoppingCart: (open: boolean) => void;
};
const ContinueShoppingBtn: FunctionalComponent<ContinueShoppingBtnProps> = ({
  toggleShoppingCart,
}: ContinueShoppingBtnProps) => {
  return (
    <button
      type="button"
      className={"btn btn--outlined"}
      onClick={() => {
        document.dispatchEvent(new CustomEvent(SHOPPING_CART_EVENTS.CLOSE_BY_SHOP_MORE_BUTTON));
        toggleShoppingCart(false);
      }}
    >
      Fortsett å handle
    </button>
  );
};
