import type { AdditionalServices, MbbBundle, Offering, Subscription } from "../shop-types";

export type SubscriptionIds = { planId: string; serviceIds?: string[]; blockId?: string; productPage: string };

export const getOrderFormWebCheckoutUrl = (subscriptions: SubscriptionIds[]): string => {
  const url = new URL(`${window.ICE.settings?.orderFormBaseUrl}/mobil`);

  if (subscriptions?.length > 0) {
    for (const subscription of subscriptions) {
      url.searchParams.append(`pid-${subscription.planId}`, subscription.serviceIds?.join(",") || "");
      url.searchParams.append("source", subscription.productPage);
    }
  }

  if (subscriptions?.length) url.searchParams.append("addedToCart", "true");
  return url.toString().replace(/%2C/gi, ",");
};

export const getCheckoutUrlMbb = (subs: MbbBundle[]): string => {
  const mbbBaseUrl = `${window.ICE.settings?.orderFormBaseUrl}`;
  const plansIdString = subs.map((sub) => `pid-${sub.routerId}=${sub.subscriptionId}`).join("&");
  return `${mbbBaseUrl}/mbb/start?${plansIdString}`;
};

export const sumActiveSubscriptionServices = (items: AdditionalServices[]): number => {
  if (!items) return 0;

  return items.reduce((sum, item) => {
    if (item.selected) return sum + item.price;

    return sum;
  }, 0);
};

export const removeSubscriptionCardsBordersAndCounter = () => {
  // Reset borders around cards
  const selectedAboElements = document.querySelectorAll(".subscription-card__container--selected");
  for (const item of selectedAboElements) {
    item.classList.remove("subscription-card__container--selected");
  }

  // Reset counters on cards
  const subAboCountElements = document.querySelectorAll(".subscription-card__count");
  for (const item of subAboCountElements) {
    item.textContent = "";
    item.classList.remove("subscription-card__count--active");
  }
};

export const countSubscriptionCardFromShoppingCart = (subElement: Element) => {
  const subscriptionCountElement = subElement?.querySelector(".subscription-card__count");
  if (!subscriptionCountElement) return;

  subscriptionCountElement.textContent = (
    Number.parseInt(subscriptionCountElement.textContent || "0", 10) + 1
  ).toString();
};

export const highlightSubscriptionCardsFromCart = (subscriptionIds: SubscriptionIds[]) => {
  try {
    removeSubscriptionCardsBordersAndCounter();

    for (const sub of subscriptionIds) {
      const subscriptionElements = document.querySelectorAll(`[data-product-id="${sub.planId}"]`);

      for (const subElement of subscriptionElements) {
        const subscriptionCountElement = subElement?.querySelector(".subscription-card__count");
        if (!subscriptionElements || !subscriptionCountElement) return;

        subElement.classList.add("subscription-card__container--selected");
        subscriptionCountElement.classList.add("subscription-card__count--active");

        countSubscriptionCardFromShoppingCart(subElement);
      }
    }
  } catch (error) {
    console.error("An error occurred while highlighting subscription cards:", error);
  }
};

export const getSubIds = (offerings: Offering[]) => {
  return (offerings as Subscription[]).map((sub): SubscriptionIds => {
    return {
      planId: sub.planId,
      blockId: sub.blockId,
      productPage: sub.productPage,
      serviceIds: sub.additionalServices
        .filter((s) => s.selected)
        .map((s) => {
          return s.serviceId;
        })
        .filter(Boolean),
    };
  });
};

export const RemoveSpaces = (text: string) => {
  return text.replace(" ", "_");
};
