import { h, FunctionalComponent, render, Fragment } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { ShoppingCartBurger } from "./shopping-cart-burger";
import { ShoppingCartHeader } from "./shopping-cart-header";
import { OfferingsListView } from "./offerings-list-view";
import { Offering, Subscription, voiceShopStoreStateType } from "../shop-types";
import { shoppingCartEventHandler } from "./shopping-cart-event-handler";
import {
  getSubIds,
  highlightSubscriptionCardsFromCart,
  removeSubscriptionCardsBordersAndCounter,
} from "../Helpers/shopping-cart-utils";
import { CSSTransition } from "preact-transitioning";
import { IceAnimationHeightContext } from "../../../Shared/Components/IceAnimation/IceAnimationHeightProvider";
import { sendGA4ViewCartData } from "@shared/Analytics/ecommerce/voice/voice-analytics";
import { sendGA4FunnelData } from "@shared/Analytics/content/funnel-analytics";
import { SHOPPING_CART_EVENTS } from "./shopping-cart-constants";
import { useFocusTrap } from "@shared/Hooks/focus-trap-hook";
import { useKeyDown } from "@shared/Hooks/use-key-down-hook";
import { useClickOutside } from "@shared/Hooks/click-outside-hook";
import { MbbShoppingCartFooter, VoiceShoppingCartFooter } from "./shopping-cart-footer";

export type ShoppingCartProps = {
  shoppingCartRootEl: HTMLElement;
  useVoiceStore: voiceShopStoreStateType;
};

export const VoiceShoppingCart: FunctionalComponent<ShoppingCartProps> = ({
  useVoiceStore,
  shoppingCartRootEl,
}: ShoppingCartProps) => {
  const shoppingBurgerRef = useRef<HTMLElement>();

  const [openCart, setOpenCart] = useState<boolean>(false);
  const openButton = useRef<HTMLElement>();

  const shoppingCartRef = useRef<HTMLDivElement>(null);
  const [lastOfferingId, setlastOfferingId] = useState<string | undefined>(undefined);

  const setOpenButtonRef = (caller: HTMLElement) => {
    openButton.current = caller;
  };

  const setShoppingBurgerRef = (element: HTMLElement): void => {
    shoppingBurgerRef.current = element;
  };

  const toggleShoppingCart = (open: boolean, offeringId?: string) => {
    setOpenCart(open);
    setlastOfferingId(offeringId);
    if (open) return;
    if (openButton.current) {
      setTimeout(() => {
        openButton.current?.focus();
        openButton.current = undefined;
      }, 10);
      return;
    }
    if (shoppingBurgerRef.current) {
      shoppingBurgerRef.current.focus();
    }
  };

  useEffect(() => {
    // when opening from shoppingcart button, focus on shoppingcart
    if (openCart && !lastOfferingId && shoppingCartRef?.current) {
      shoppingCartRef.current.focus();
    }
    // When opening shoppingcart after maxofferings dialog - focus on go to order button
    if (lastOfferingId && shoppingCartRef?.current) {
      if (lastOfferingId === "btn-goto-order") {
        const offering = document.querySelector<HTMLElement>(`#btn-goto-order`);
        if (offering) {
          offering.focus();
        }
        return;
      }

      // When add a new offering, focus on the new offering
      const offering = document.querySelector<HTMLElement>(
        `#AddServices_${lastOfferingId},#change-shopping_cart_${lastOfferingId}`,
      );

      setTimeout(() => {
        if (offering) {
          offering.focus();
        }
      });
    }
  }, [lastOfferingId, shoppingCartRef, openCart]);

  const trapRef = useFocusTrap<HTMLDivElement>();
  useClickOutside(shoppingCartRef, () => {
    if (openCart) toggleShoppingCart(false);
  });
  useKeyDown("Escape", () => toggleShoppingCart(false));

  const numberOfProducts = useVoiceStore((state) => state.numberOfProducts);
  const isEmpty = numberOfProducts() === 0;
  const isMbb = useVoiceStore((state) => state.isMbb);
  const isBusiness = useVoiceStore((state) => state.isBusiness);
  const offeringsList = useVoiceStore((state) => state.offerings);

  const updateService = useVoiceStore((state) => state.updateService);
  const removeOffering = useVoiceStore((state) => state.removeOffering);

  const getPrices = useVoiceStore((state) => state.getPrice);
  const [totalSum, setTotalSum] = useState<number>(getPrices().total);
  shoppingCartEventHandler({ useVoiceStore, setOpenButtonRef, toggleShoppingCart });

  useVoiceStore.subscribe((store) => {
    if (store.offerings.length === 0) {
      toggleShoppingCart(false);
    }
  });

  useEffect(() => {
    const productCount = numberOfProducts();
    const sum = getPrices();
    setTotalSum(sum.total);

    render(
      h(ShoppingCartBurger, {
        productCount,
        totalSum: sum.total,
        setOpenShoppingCart: setOpenCart,
        openShoppingCart: openCart,
        setShoppingBurgerRef,
      }),
      shoppingCartRootEl,
    );

    const body = document.querySelector<HTMLElement>("body");
    if (!body) throw new Error("Body element not found on document");

    if (openCart) {
      body.classList.add("disable-scrolling");
    } else {
      body.classList.remove("disable-scrolling");
    }
  }, [openCart, offeringsList]);

  useEffect(() => {
    if (offeringsList.length === 0) {
      removeSubscriptionCardsBordersAndCounter();
    } else if (!isMbb()) {
      highlightSubscriptionCardsFromCart(getSubIds(offeringsList));
    }
  }, [offeringsList]);

  // Dispatch custom event whenever openCart state changes, used by Optimizely Experiments
  useEffect(() => {
    const event = new CustomEvent(SHOPPING_CART_EVENTS.OPEN_STATE_CHANGED, { detail: { openCart } });
    window.dispatchEvent(event);
  }, [openCart]);

  useEffect(() => {
    function handleOpenCartEvent(event: CustomEvent<{ openState: boolean }>) {
      const newOpenState = event.detail?.openState ?? false;
      if (newOpenState) {
        setOpenCart(newOpenState);
        return;
      }
      toggleShoppingCart(false);
    }
    window.addEventListener(SHOPPING_CART_EVENTS.SET_OPEN_STATE, handleOpenCartEvent as EventListener);
    return () => {
      window.removeEventListener(SHOPPING_CART_EVENTS.SET_OPEN_STATE, handleOpenCartEvent as EventListener);
    };
  }, []);

  const removeOfferingHandler = (id: string) => {
    removeOffering(id);
  };

  const removeServiceHandler = (id: string, serviceName: string) => {
    updateService(id, serviceName, false);
  };

  const { stopHeightAnimation } = useContext(IceAnimationHeightContext);

  const trackOfferingsForGA4 = (offeringsList: Offering[], isMbb: boolean, isBusiness: boolean): void => {
    if (offeringsList.length === 0) return;

    void sendGA4ViewCartData(offeringsList, isMbb, isBusiness);
    void sendGA4FunnelData(offeringsList as Subscription[]);
  };

  return (
    <div ref={trapRef}>
      <div
        className={`${openCart ? "shopping-cart--open-overlay" : ""}`}
        aria-hidden={`${openCart ? "false" : "true"}`}
      />
      <CSSTransition
        in={openCart}
        classNames="shopping-cart shopping-cart--open"
        onEnter={() => {
          stopHeightAnimation!.value = true;
        }}
        onEntered={() => {
          stopHeightAnimation!.value = false;

          trackOfferingsForGA4(offeringsList, isMbb(), isBusiness());
        }}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, { once: true, capture: false });
        }}
      >
        <div
          role="dialog"
          aria-modal="true"
          tabIndex={-1}
          ref={shoppingCartRef}
          aria-labelledby="shopping-cart-heading"
        >
          <ShoppingCartHeader closeShoppingCart={toggleShoppingCart} productCount={numberOfProducts()} />
          {isEmpty ? (
            <div className="subscriptions--empty p-075">
              <p>Det er ingen varer i handlekurven</p>
            </div>
          ) : (
            <Fragment>
              <OfferingsListView
                removeOffering={removeOfferingHandler}
                offerings={offeringsList}
                removeService={removeServiceHandler}
                useVoiceStore={useVoiceStore}
              />
              {isMbb() ? (
                <MbbShoppingCartFooter
                  offerings={offeringsList}
                  useVoiceStore={useVoiceStore}
                  toggleShoppingCart={toggleShoppingCart}
                />
              ) : (
                <VoiceShoppingCartFooter
                  offerings={offeringsList}
                  totalSum={totalSum}
                  toggleShoppingCart={toggleShoppingCart}
                />
              )}
            </Fragment>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};
