import { h, type FunctionalComponent, Fragment } from "preact";
import { CloseIcon, Price } from "@shared/Components";
import type { AdditionalServices, Subscription, voiceShopStoreStateType } from "../shop-types";
import { useEffect, useRef, useState } from "preact/hooks";
import { RemoveSpaces } from "../Helpers/shopping-cart-utils";

import * as Dialog from "@radix-ui/react-dialog";
import { AddserviceDialog } from "./Dialogs/addservice-dialog";
import { fetchProductBlockDataService } from "../Helpers/subscription-builder.service";
import type { ProductBlockDataModel } from "../Helpers/subscription-builder.types";
import { ExternalAddserviceDialog } from "./Dialogs/external-addservice-dialog";
import { sendGA4AddonEvent } from "@shared/Analytics/ecommerce/voice/voice-addon-analytics";
import {
  IceAnimationCloseHandler,
  IceAnimationCreateState,
  IceAnimationHeight,
} from "@shared/Components/IceAnimation/IceAnimationHeight";

type SubscriptionExternalServiceItemProps = {
  sub: Subscription;
  useVoiceStore: voiceShopStoreStateType;
};

export const SubscriptionExternalServiceItem: FunctionalComponent<SubscriptionExternalServiceItemProps> = ({
  sub,
  useVoiceStore,
}: SubscriptionExternalServiceItemProps) => {
  const selectedServices = sub.additionalServices.filter((x) => x.selected === true);
  if (!selectedServices) return;

  const [productData, setProductData] = useState<ProductBlockDataModel>();

  useEffect(() => {
    const fetchData = async () => {
      const productDataObj = await fetchProductBlockDataService(sub.blockId);
      setProductData(productDataObj);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      {selectedServices.map((item) => (
        <div key={item.title} className={"subscriptions__row subscriptions__row__service background--blue-90"}>
          <div className="flex flex--split">
            <span className="text--bold-600">{item.title}</span>
            <span className="visually-hidden">
              <Price amount={item.price} /> kroner per måned
            </span>
            <ExternalAddserviceDialog
              currentServiceTitle={item.title}
              offeringsId={sub.offeringId}
              productData={productData as ProductBlockDataModel}
              useVoiceStore={useVoiceStore}
              sub={sub}
            />
          </div>
          <div aria-hidden="true">
            <Price amount={item.price} useKr={true} addMnd={true} />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

type SubscriptionServiceItemProps = {
  removeServiceHandler: (e: Event, offeringId: string, serviceName: string) => void;
  sub: Subscription;
  useVoiceStore: voiceShopStoreStateType;
};

export const SubscriptionServiceList: FunctionalComponent<SubscriptionServiceItemProps> = ({
  sub,
  removeServiceHandler,
  useVoiceStore,
}: SubscriptionServiceItemProps) => {
  const activeItems = sub.additionalServices.filter((x) => x.selected === true);

  // Todo Use this
  const checkAddButtonVisibility = () => {
    return sub.additionalServices?.some((x) => x.selected === false);
  };

  const [productData, setProductData] = useState<ProductBlockDataModel | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const productDataObj = await fetchProductBlockDataService(sub.blockId);
      setProductData(productDataObj);
    };

    fetchData().catch(console.error);
  }, []);

  const updateGaState = (open: boolean) => {
    sendGA4AddonEvent(open ? "open" : "close", false);
  };

  return (
    <Fragment>
      {activeItems?.map((item) => (
        <ServiceItemView
          key={item.title}
          offeringsId={sub.offeringId}
          serviceItem={item}
          removeServiceHandler={removeServiceHandler}
        />
      ))}

      <IceAnimationHeight open={checkAddButtonVisibility()} key="AddButton">
        <div className="subscriptions__row__item">
          <Dialog.Root onOpenChange={updateGaState}>
            <Dialog.Trigger asChild>
              <button
                type="button"
                id={`AddServices_${sub.offeringId}`}
                className="link disable-text-decoration subscriptions__addservice-btn link--hoverable"
                aria-label={`Legg til tilleggstjenester for ${sub.name}`}
              >
                Legg til tilleggstjenester <span className="text--bold-700">+</span>
              </button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="radix-modal__overlay" />
              <Dialog.Content className="radix-modal__content addservice-dialog">
                <AddserviceDialog
                  productData={productData}
                  useVoiceStore={useVoiceStore}
                  offeringsId={sub.offeringId}
                  additionalServices={sub.additionalServices}
                />
                <Dialog.Close asChild>
                  <button type="button" className="radix-modal__close" aria-label="Close">
                    <CloseIcon />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </IceAnimationHeight>
    </Fragment>
  );
};

type ServiceItemProps = {
  offeringsId: string;
  serviceItem: AdditionalServices;
  removeServiceHandler: (e: Event, offeringId: string, productId: string) => void;
};

export const ServiceItemView: FunctionalComponent<ServiceItemProps> = ({
  offeringsId,
  serviceItem,
  removeServiceHandler,
}: ServiceItemProps) => {
  const serviceContainerRef = useRef<HTMLDivElement>(null);

  const onClickhandler = (e: Event) => {
    removeServiceHandler(e, offeringsId, serviceItem.serviceId);
  };

  const [animationState, setAnimationState] = IceAnimationCreateState();

  return (
    <IceAnimationHeight animationState={animationState} key={serviceItem.serviceId}>
      <div key={serviceItem.serviceId} className={"subscriptions__row "} ref={serviceContainerRef}>
        <div className="subscriptions__row__service background--yellow-90">
          <div className="flex flex--split">
            <span>{serviceItem.title}</span>
            <span className="visually-hidden">
              <Price amount={serviceItem.price} /> kroner per måned
            </span>
            <button
              type="button"
              id={`remove_${RemoveSpaces(serviceItem.title)}_${offeringsId}`}
              className="subscriptions__block__btn link"
              onClick={IceAnimationCloseHandler(setAnimationState, onClickhandler)}
              aria-label={`Fjern ${serviceItem.title}`}
            >
              <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                <use xlinkHref="#ice-trash" />
              </svg>
            </button>
          </div>

          <div aria-hidden="true" data-testid="servicepriceitem">
            <Price amount={serviceItem.price} useKr={true} addMnd={true} />
          </div>
        </div>
      </div>
    </IceAnimationHeight>
  );
};
