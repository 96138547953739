import { RefObject } from "preact";
import { useEffect, useRef } from "preact/hooks";

const FOCUSABLE_ELEMENTS_SELECTOR = "button, a[href], input, select, textarea, [tabindex]:not([tabindex='-1'])";

export const useFocusTrap = <T extends HTMLElement>(elementRef?: RefObject<T>) => {
  const internalRef = useRef<T>(null);
  const ref = elementRef || internalRef;

  const handleFocus = (e: KeyboardEvent): void => {
    const focusableElements = ref.current?.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR);
    if (!focusableElements?.length) return;

    const firstFocusableElement = focusableElements[0] as HTMLElement;
    const lastFocusableElement = focusableElements[focusableElements.length - 1] as HTMLElement;

    if (e.key !== "Tab") {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    ref.current?.addEventListener("keydown", handleFocus);
    return () => {
      ref.current?.removeEventListener("keydown", handleFocus);
    };
  }, []);

  return ref;
};
