import { h, type FunctionalComponent, Fragment } from "preact";
import { Price } from "@shared/Components";
import { SubscriptionExternalServiceItem, SubscriptionServiceList } from "./subscription-service-item";
import type { Subscription, voiceShopStoreStateType } from "../shop-types";
import {
  IceAnimationCloseHandler,
  IceAnimationCreateState,
  IceAnimationHeight,
} from "@shared/Components/IceAnimation/IceAnimationHeight";

type OfferingViewProps = {
  sub: Subscription;
  removeOffering: (id: string) => void;
  removeService: (id: string, serviceName: string) => void;
  useVoiceStore: voiceShopStoreStateType;
};

const SubscriptionView: FunctionalComponent<OfferingViewProps> = ({
  removeOffering,
  removeService,
  sub,
  useVoiceStore,
}: OfferingViewProps) => {
  const removeOfferingHandler = (e: Event) => {
    e.preventDefault();
    removeOffering(sub.offeringId);
  };

  const removeServiceHandler = (e: Event, offeringId: string, serviceName: string) => {
    e.preventDefault();
    e.stopPropagation();

    removeService(offeringId, serviceName);
  };

  const [animationState, setAnimationState] = IceAnimationCreateState();

  return (
    <Fragment>
      <IceAnimationHeight key={sub.offeringId} animationState={animationState}>
        <li className="subscriptions__block" tabIndex={-1}>
          <div className="subscriptions__block__inner">
            <div className="flex flex--split">
              <span className="font-size-18 text--bold-700">{sub.name}</span>
              <span className="visually-hidden">{sub.price} kroner per måned</span>
              <button
                type="button"
                id={`remove_${sub.name.replace(" ", "")}_${sub.offeringId}`}
                className="subscriptions__block__btn link"
                onClick={IceAnimationCloseHandler(setAnimationState, removeOfferingHandler)}
                aria-label={`Fjern abonnementet ${sub.name}`}
              >
                Fjern{" "}
                <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                  <use xlinkHref="#ice-trash" />
                </svg>
              </button>
            </div>

            <div className="subscriptions__row" aria-hidden="true">
              {sub.pricenocampaign > 0 && (
                <span className="">
                  <span className="line-through">
                    <Price amount={sub.price} />
                  </span>
                </span>
              )}

              <span aria-hidden="true" className="subscriptions__list__right">
                <Price amount={sub.pricenocampaign > 0 ? sub.pricenocampaign : sub.price} useKr={true} addMnd={true} />
              </span>
              <span className="visually-hidden">
                {sub.pricenocampaign > 0 ? sub.pricenocampaign : sub.price} kroner per måned
              </span>
            </div>

            {!sub.showExternalServiceDialog ? (
              <SubscriptionServiceList
                removeServiceHandler={removeServiceHandler}
                sub={sub}
                useVoiceStore={useVoiceStore}
              />
            ) : (
              <SubscriptionExternalServiceItem sub={sub} useVoiceStore={useVoiceStore} />
            )}
          </div>
        </li>
      </IceAnimationHeight>
    </Fragment>
  );
};

export default SubscriptionView;
