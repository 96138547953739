import { RefObject } from "preact";
import { useEffect } from "preact/hooks";

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current) return;

      if (ref.current.contains(event.target as Node)) return;

      // Check if click was inside any other dialog element (shopping cart triggers a dialog thats not in the ref)
      const clickTarget = event.target as HTMLElement;
      const clickedDialog = clickTarget.closest('[role="dialog"], dialog');

      if (clickedDialog && !ref.current.contains(clickedDialog)) return;

      callback();
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, callback]);
};
